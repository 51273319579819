import React, { useState, useEffect } from "react"
import CountUp from 'react-countup';
import mapboxgl from "!mapbox-gl"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { appUrl } from '../helpers/helpers'
import { Link } from 'gatsby';
import { ddp } from "../api/ddp"
import './for-growers-shippers.less';

import phoneicon from '../images/produceplan-iphoneicon.svg';
import shipper1Img from '../images/shipper-1.png';
import shipper2Img from '../images/shipper-2.png';
import shipper3Img from '../images/shipper-3.png';
import supplier2Img from '../images/supplier-2.png';

const usaCenterCoords = [-100, 40];
const loadingHtml = 'Loading...';
const redactedFarmHtml = '<span class="blurred-text">Redacted Farm</span>';
const signupUrl = `${appUrl}/signup?source=produceplan.com/for-growers-shippers`;
const signupUrl2 = `${appUrl}/signup?source=produceplan.com/`;

const ForGrowersShippers = () => {
  const [farms, setFarms] = useState([]);
  const [geocoderRendered, setGeocoderRendered] = useState(false);

  useEffect(() => {
    const getPinsMethodId = ddp.method("farms/getPins", [{}]);
    ddp.on("result", message => {
      if (message.id === getPinsMethodId && !message.error && message.result?.length) {
        setFarms(message.result);
      }
    })
  }, [farms.length]);

  useEffect(() => {
    setTimeout(() => {
      try {
        mapboxgl.accessToken = 'pk.eyJ1IjoidXNlZnVsaW8iLCJhIjoiVzN0dmphNCJ9.Sn5yqJ5uLPLyKCWMqETN9g';
        const map = new mapboxgl.Map({
          container: 'map', // container ID
          style: 'mapbox://styles/mapbox/streets-v11', // style URL
          center: usaCenterCoords, // starting position [lng, lat]
          zoom: 3, // starting zoom
        });
    
        // Add zoom and rotation controls to the map.
        map.addControl(new mapboxgl.NavigationControl()); 
  
        // Create the geocoder control 
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          flyTo: false,
          placeholder: 'Where are you growing Produce?',
          clearOnBlur: true,
          enableEventLogging: false,
          marker: false,
          countries: 'us,mx',
        });

        geocoder.on('result', function({ result }) {
          // const isRegion = result?.place_type?.[0] === "region"; // is state?
          document.getElementById("map-header").scrollIntoView({ behavior: "smooth" });
          // map.flyTo({ 
          //   center: result?.center || usaCenterCoords, 
          //   zoom: isRegion ? 6 : 5,
          // })
        });
  
        const geocoderElement = document.getElementById('geocoder');
        
        // Attach geocoder to search input
        // if (geocoderElement && !geocoderRendered) {
          geocoderElement.appendChild(geocoder.onAdd(map));
          // map.addControl(geocoder);
          setGeocoderRendered(true);
        // }
         
    
        // Show markers for farms
        for (let i = 0; i < farms.length; i++) {
          const farm = farms[i];
          const initHtml = `<div class="map-popup"><p><strong>Farm name</strong>: ${redactedFarmHtml}</p><div>${loadingHtml}</div></div>`;

          if (farm.geoJson) {
            const popup = new mapboxgl.Popup().setHTML(initHtml);
            new mapboxgl.Marker({
              color: farm.geoJson.properties['marker-color'],
              draggable: false
            })
            .setPopup(popup) // add popup
            .setLngLat(farm.geoJson.geometry.coordinates)
            .addTo(map);

            popup.on('open', () => {
              popup.setHTML(initHtml);
              const getFarmDataForMapMethodId = ddp.method("farms/getFarmDataForMap", [{ farmId: farm._id }]);
              ddp.on("result", message => {
                if (message.id === getFarmDataForMapMethodId && !message.error && message.result) {
                  const { businessName, grossArea, commodityNames } = message.result;
                  const farmData = {
                    'Farm name': redactedFarmHtml,
                    'Business name': '<span class="blurred-text">Redacted Business LLC</span>',
                  };

                  if (grossArea) { farmData['Total Area'] = grossArea; };
                  if (commodityNames) { farmData['Commodities'] = commodityNames; };

                  let popupHtml = '<div class="map-popup">';
                  for (let i = 0; i < Object.keys(farmData).length; i++) {
                    const label = Object.keys(farmData)[i];
                    popupHtml += `<p><strong>${label}</strong>: ${farmData[label]}</p>`;
                  }

                  popupHtml += `<a href="${signupUrl}" target="_blank" class="impersonate-btn">Get in touch now!</a>`;
                  popupHtml += '</div>';

                  popup.setHTML(popupHtml);
                }
              })
            });
          }
        }
      } catch (error) {
        console.warn('error', error)
      }
    }, 1000);   
  }, [farms.length]);


  return (
    <Layout headerColor="#CDFEF3">
      <SEO title="Shipping Logistics Planning" />
      <div className="jumbotron-container">
        <div className="news">
          Sostena, FarmRaise strategically align to make USDA Finance programs more accessible. <Link to="/press-release">Click here to learn more.</Link>
        </div>
        <div className="jumbotron">
          <div className="container">
            <div className="jumbotron-content">
              <h3>Grow locally, trade globally</h3>
              <div className="jumbotron-search">
                <div id="geocoder" className="jumbotron-search-input"></div>
                {/* <!-- <button data-action="find-now">Find Now</button> --> */}
              </div>
              <span className="search-subtext">
                <strong>{farms.length || <CountUp end={1078} duration={5} />}</strong> farms and counting use Produceplan™ platform to grow, pack and ship sustainable produce. Sign up for the leading digital <span style={{ fontWeight: 'bold' }}>produce</span> platform today!
              </span>
            </div>
          </div>
        </div>
        
        <div id="forShippers">
          <div className="row" id="shipper-business-areas">
            <h4>
              Grow, pack and ship on Produceplan™ <br/>
              <a href={signupUrl} className="btn orange btn-cta">Sign Up</a>
            </h4>
            <p style={{fontWeight:600}}>Our ERP helps your company schedule, grow, sell and transport all your fresh produce with our end-to-end cloud based features – from first planting to final delivery.</p>
            <div className="col s12 m4 shippers-col">
              <img
                src={shipper1Img}
                alt="Suppliers"
              />
              <p>Digitize your farming</p>
              <div className="center">
                <a href={signupUrl} className="shippers-lnk">Create a Produceplan™ account</a>
              </div>
            </div>
            <div className="col s12 m4 shippers-col">
              <img src={shipper2Img} alt="Buyers" />
              <p>
                Trade now or plan for next year
              </p>
              <div className="center">
                <a href={signupUrl} className="shippers-lnk">Produceplan™ let's you sell seamlessly</a>
              </div>
            </div>
            <div className="col s12 m4 shippers-col">
              <img src={shipper3Img} alt="Growers" />
              <p>Always fresh and sustainable</p>
              <div className="center">
                <a href={signupUrl} className="shippers-lnk">Brokers & Shippers can sign up here</a>
              </div>
            </div>
          </div>
        </div>

        <div className="map-header" id="map-header">
          <h4>The Produceplan™ Family Farming Network</h4>
          <Link to="/contact" className="shippers-lnk right">View all growers/shippers</Link>
        </div>
        <div id='map' className='map'></div>

        <div id="howItWorks">
          <div className="container">
            <div className="row">
              <div className="col s12 m12">
                <h4 style={{fontWeight:600}}>How Produceplan™ works</h4>
              </div>
            </div>

            <div className="row">
              <div className="col s12 m6 center relative">
                &nbsp;
                <img
                  class="iphone-img hide-on-med-and-down"
                  src={phoneicon}
                  alt="Produceplan"
                  height="420"
                />

                <img
                  class="hide-on-large-only"
                  src={phoneicon}
                  alt="Produceplan"
                  height="270"
                />
              </div>
              <div className="col s12 m6">
                <div className="hide-on-med-and-down">
                  <br/><br/><br/>
                </div>
                <p>
                  You sign up and get instant access to a  <br className="hide-on-med-and-down"/>
                  modern cloud-based software platform for  <br className="hide-on-med-and-down"/>
                  the fresh produce industry.
                </p>
              </div>
            </div>
            <div className="hide-on-med-and-down">
              <br/><br/><br/><br/><br/>
            </div>
            <div className="row">
              <div className="col s12 m6 hide-on-med-and-down">
                <p className="left-paragraph">
                  <br/><br/><br/><br/><br/><br/><br/>
                  Grow your revenue,  <br className="hide-on-med-and-down"/>
                  increase operational efficiency, <br className="hide-on-med-and-down"/>
                  and mitigate compliance risk with our <br className="hide-on-med-and-down"/>
                  adaptable and comprehensive platform.
                </p>
              </div>
              <div className="col s12 m6 left relative">
                &nbsp;
                <img
                  class="shipper-img hide-on-med-and-down"
                  src={shipper1Img}
                  height="360"
                />
                <img
                  class="shipper-img hide-on-large-only"
                  src={shipper1Img}
                  height="270"
                />
              </div>
              <div className="col s12 m6 hide-on-large-only">
                <p>
                Grow your revenue, increase operational efficiency, and mitigate compliance risk with our adaptable and comprehensive platform.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col s12 m6">
                &nbsp;
                <img
                  class="suplier-img right hide-on-med-and-down"
                  src={supplier2Img}
                  width="420"
                />
                <img
                  class="suplier-img hide-on-large-only"
                  src={supplier2Img}
                  width="100%"
                />
              </div>
              <div className="col s12 m6">
                <p>
                  <br/><br/><br className="hide-on-med-and-down"/>
                  Reduce waste, accelerate sales velocity, <br className="hide-on-med-and-down"/>
                  and provide end-to-end traceability while <br className="hide-on-med-and-down"/>
                  leveraging data to increase profitability.
                </p>
              </div>
            </div>

            <br/><br/>
          </div>
        </div>

        <br/>
        <div className="center">
          <a href={signupUrl2} className="btn blue-grey darken-3 btn-cta">Get a full virtual tour</a>
          <span className="btn-row-or"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          <a href={signupUrl} className="btn orange btn-cta">Sign Up</a>
        </div>
        <br/>
      </div>
    </Layout>
  )
};

export default ForGrowersShippers
